<template>
    <div class="row">
        <div class="col-lg-6 col-12 h5 p-4">
            Claims pending for approval
            <span class="claim_tp px-5 py-3 ml-5">
                {{pendingClaims}}
            </span>
        </div>
        <div class="col-lg-6 col-12 h5 p-4">
            Total number of claims
            <span class="claim_tp px-5 py-3 ml-5">
                {{totalClaims}}
            </span>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name:'TotalClaims',
    data() {
        return {
            totalClaims:0,
            pendingClaims:0,
        }
    },
    computed: {
    ...mapGetters(["total_claim","claim_request_pending"]),
  },
  mounted(){
    this.asignValues();
  },
  watch:{
    total_claim(){
      this.asignValues();
      },
  },
  methods:{
    asignValues(){
      this.totalClaims = this.total_claim;
      this.pendingClaims = this.claim_request_pending;
      },
    }
}
</script>
<style scoped>
.claim_tp{
    background-color: #b9e2f1 !important;
    border-radius: 10px;
}

</style>
