<template>
      <v-container>
        <v-card outlined elevation="0" class="mb-1">
          <div class="mt-4 mb-0">
            <ItcannerHeader/>
          </div>
        </v-card>
    <v-card outlined>
      <v-card-text>
        <v-row>
          <v-col align-self="center">
            <TotalClaims :totalClaims="totalClaims" :pendingClaims="pendingClaims" />
           <v-card-title
            class="display-1 text-color text--darken-3 font-weight-bold"
            >
                My Claims
            </v-card-title>
            <FilterPopUp v-if="myClaimFilterPopUp.filter" :filter-data="myClaimFilterPopUp" @myClaimFilterPopUp='getName'/>
            <v-data-table
                :headers="headers"
                :items="desserts"
                :items-per-page="10"
                class="elevation-1"
                style="text-align-last:center"
            >
                <template v-slot:top>
                  <v-row
                    no-gutters
                    style="text-align-last: right"
                  >
                    <v-col
                    class="text-align-right"
                    cols="12">
                      <v-icon class="pa-4 font-weight-bold" size="30px" @click="openFilterPopUp">
                          mdi-filter-variant
                      </v-icon>
                    </v-col>
                  </v-row>
                    </template>
                    <template v-slot:item.attached="{ item }">
                    <v-btn
                        depressed
                        color="primary"
                        @click="downloadAttached(item)"
                        v-if="item.fileCount > 0"
                    >
                    <v-icon>mdi-download</v-icon>
                    </v-btn>
                    <div class="" v-else>
                      -
                    </div>
                </template>

                <template v-slot:item.action="{ item }">
                <!-- <router-link to="/edit-request/"+item.id> -->
                <router-link :to="'/edit-request/' + item.id" v-if="item.status == 'Rejected'">
                  <v-btn
                      depressed
                      color="primary"
                  >
                      <!-- {{ item.status }} -->
                      Edit
                  </v-btn>
                </router-link>
                  <div class="" v-else>
                    -
                  </div>
                </template>
            </v-data-table>
          </v-col>


        </v-row>
      </v-card-text>
    </v-card>

  </v-container>
</template>
<script>
import FilterPopUp from "./FilterPopUp.vue"
import TotalClaims from "./TotalClaims.vue"
import ItcannerHeader from "../ItcannerHeader.vue"
import axios, { Axios } from "axios"
export default({
    name: "MyClaims",
    components: {
      FilterPopUp,
      ItcannerHeader,
      TotalClaims
    },
    data() {
        return {
            totalClaims:10,
            pendingClaims:'10',
            myClaimFilterPopUp: {
              filter:false,
              search:'',
              brand:'',
              status:[],
              cause:'',
            },
            headers: [
          {
            text: 'S. No.',
            align: 'start',
            value: 'sino',sortable: false
          },
          { text: 'Claim ID', value: 'claimid',sortable: false },
          { text: 'Brand', value: 'brand',sortable: false },
          { text: 'Order ID', value: 'orderid',sortable: false },
          { text: 'Attachments', value: 'attached',sortable: false },
          { text: 'Cashback Amount', value: 'cashbackAmount',sortable: false },
          { text: 'Status', value: 'status' ,sortable: false },
          { text: 'Action', value: 'action' ,sortable: false },
          { text: 'Cause', value: 'cause' },
        ],
        desserts: [],
        dialog: false,
        dialogApprove: false,
        editedIndex: -1,
        editedItem: {
            sino: 0,
            id: 0,
            claimid: 0,
            brand: 0,
            orderid: 0,
            attached: 0,
            cashbackAmount: 0,
            status: [],
            cause: 0,
            fileCount:'',
        },
        defaultItem: {
            sino: 0,
            id: 0,
            claimid: 0,
            brand: 0,
            orderid: 0,
            attached: 0,
            cashbackAmount: 0,
            status: [],
            cause: 0,
            fileCount:'',
        },
        }
    },
    watch: {
      dialogApprove (val) {
        val || this.closeApprove()
      },
    },
    created() {
        this.getClaimData();
    },
    methods: {
      getName(value) {
        this.myClaimFilterPopUp = value;
          console.log(value);
          this.getClaimData();
      },
      openFilterPopUp(){
        this.myClaimFilterPopUp.filter = true;
        console.log(this.myClaimFilterPopUp)
      },
      async getClaimData(){
        var statusarray = []
          var statusarraydata = this.myClaimFilterPopUp.status
          statusarraydata.forEach((statusText)=>{
            statusarray.push(statusText == 'Pending' ? 0 : statusText == 'Processing' ? 1 : statusText == 'Approved' ? 2 : statusText == 'Rejected' ? 3 : '');
          })
          this.$store.dispatch("setLoader");
        await axios.get('my-claims',{
                          params: {
                            claim_order_id: this.myClaimFilterPopUp.search,
                            brand: this.myClaimFilterPopUp.brand,
                            status: statusarray,
                            cause: this.myClaimFilterPopUp.cause,
                          }
                        })
              .then(response => {
                console.log(response.data.data);
                if(response.data.status == true){
                  this.desserts = [];
                  var allClaims = response.data.data;
                  allClaims.forEach((claims,index)=>{
                  this.desserts.push(
                      {
                          sino: index+1,
                          id: claims.id, 
                          claimid: claims.claim_id,
                          brand: claims.brand_name,
                          orderid: claims.order_id,
                          attached: 'XDX',
                          cashbackAmount: claims.currency + " " +claims.amount,
                          status: claims.status == 0 ? 'Pending' : claims.status == 1 ? 'Processing' : claims.status == 2 ? 'Approved' : 'Rejected',
                          cause: claims.cause,
                          fileCount: claims.fileCount,
                      },
                  );
                  });
                }
                else{
                  
                }
              });
              this.$store.dispatch("hideLoader");
        },
        approveItem (item) {
        this.editedIndex = this.desserts.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogApprove = true
      },

      approveItemConfirm (sino) {
          this.desserts[sino-1].action = "Claimed";
        // this.desserts.splice(this.editedIndex, 1)
        this.closeApprove()
      },


      closeApprove () {
        this.dialogApprove = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async downloadAttached(item){
        await axios.post('download-attachment',{
                            id: item.id
                        },{
                          responseType: 'blob'
                         })
              .then(response => {

                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'file.zip');
                document.body.appendChild(fileLink);
                fileLink.click()
              });
        console.log(item);
        var id = item.id;
        console.log(id);
      },

      // editItem(item){
      //   console.log('asdf');
      //   this.$router.push({ name: 'editRequest', params: {item: item} });
      // },

    },
})
</script>
<style>
.text-align-right{
  text-align: right;
}
</style>
